<script setup>
import { ref } from "vue";
import { hourAndMinutes, limitText } from "../../../libs/formaters.js"
import ContentKind from "./ContentKind.vue";
import PlaytubeIcon from "./icons/PlaytubeIcon.vue";
import ClockIcon from "./icons/ClockIcon.vue";
import Authors from "./Authors.vue";
import PlusIcon from "./icons/PlusIcon.vue";
const props = defineProps({
  course: {
    type: Object
  },
  noResponsiveMode: {
    type: Boolean,
    default: false
  }
})

</script>
<template>
  <article class="w-[351px]  min-h-[97px] relative px-2 border py-2 md:py-3.5 md:px-3.5 border-secondary-30 rounded-2xl bg-white hover:bg-secondary-20 w-full">
    <div class="grid grid-cols-[77px_auto] gap-4 w-full">
      <div class="flex items-center relative h-[52px]">
          <figure 
                :class="'bg-secondary-80 size-[76px] mt-5'" 
                class="flex justify-center items-center size-[52px] p-2  rounded">
            <img loading="lazy" onerror="this.src='/images/iconologo-ow-white.svg'" class="w-[52px] h-auto" :src="course.logo_image || course.logo_image_url" alt="Icono de la tecnología">
            <div :class="'left-[59px] -top-[5px]' " class="absolute  rounded  bg-secondary-100 flex justify-center items-center size-[21px]"><PlusIcon class="text-white size-3" /></div>
          </figure>
      </div>
      <div class="flex flex-col justify-between min-h-[77px]">
        <div class="grid-item-list-3  min-w-full">
          <h3 class="lg:text-[22px] text-[18px]  font-semibold leading-[26px] text-gray-900">
            <a class="extend" :href="`/rutas/${course.slug}/`"><slot>{{limitText(course.name, 8)}}</slot></a>
          </h3>
        </div>
        <div :class="{'justify-between items-end w-full' : noResponsiveMode}" class="flex w-full  grid-item-list-5 justify-between">
          <ContentKind :kind="course.kind || 'carrera'" :kindLabel="course.label || 'Ruta'" />
          <div :class="{'grid-item-list-5 flex' : !noResponsiveMode}" class="flex items-center text-xs">
            <ClockIcon class="mr-2" />
            {{hourAndMinutes(course.video_duration_in_minutes*60 || course.video_duration )}}
          </div>
        </div>
      </div>
    </div>
  </article>
</template>
<style>
.grid-item-list-1 {
  grid-area: image;
  align-self: start;
  justify-self: start;
}
.grid-item-list-2 {
  grid-area: info;
  align-self: center;
  justify-self: start;

}
.grid-item-list-2-noResponsive {
  grid-area: info;
  align-self: start;
  justify-self: end;

}
.grid-item-list-3 {
  grid-area: name;
  align-self: start;
  justify-self: start;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
.grid-item-list-4 {
  grid-area: difficulty;
  align-self: start;
  justify-self: start-list;
}
.grid-item-list-5 {
  grid-area: duration;
  align-self: center;
  justify-self: start;
}
.grid-item-list-6 {
  grid-area: description;
  align-self: start;
  justify-self: start;
}
.grid-item-list-7 {
  grid-area: valoration;
  align-self: center;
  justify-self: start;
}
.grid-container-list {
  display: grid;
  grid-template-areas:
      'image image name name name name'
      'image image name name name name'
      'image image name name name name'
      'image image info info duration duration';
}
.grid-container-list-no-responsive {
  display: grid;
  padding-top: 1rem;
  grid-template-areas:
      'image image image image image image image image image image info'
      'image image image image image image image image image image info'
      'name name name name name name name name name name name'
      'name name name name name name name name name name name'
      'name name name name name name name name name name name'
      'name name name name name name name name name name name'
      'name name name name name name name name name name name'
      'name name name name name name name name name name name'
      'duration duration duration duration duration duration duration duration duration duration duration';
}
@media screen and (min-width: 768px){
  .position-badge {
  position: relative;
  top: 0;
  left: 0;
  }
.grid-item-list-1 {
  grid-area: image;
  align-self: start;
  justify-self: start;
}
.grid-item-list-2 {
  grid-area: info;
  align-self: start;
  justify-self: end;

}
.grid-item-list-3 {
  grid-area: name;
  align-self: start;
  justify-self: start;
  max-width: 100%;
}
.grid-item-list-4 {
  grid-area: difficulty;
  align-self: center;
  justify-self: start;
}
.grid-item-list-5 {
  grid-area: duration;
  align-self: center;
  justify-self: end;
}
.grid-item-list-6 {
  grid-area: description;
  align-self: start;
  justify-self: start;
}
.grid-item-list-7 {
  grid-area: valoration;
  align-self: center;
  justify-self: start;
}
.grid-container-list {
  display: grid;
  width: 100%;
  grid-template-areas:
      'image image image info'
      'name name name  name'
      'name name name  name'
      'duration duration duration duration'
      'duration duration duration duration'
      'description description description description'
      'valoration valoration valoration valoration';
}  
.grid-container-list-no-responsive {
  display: grid;
  padding-top: 1rem;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 60px 3fr 1fr;
  grid-template-areas:
      'image image  info'
      'name name name'
      'duration duration duration'
}
}

</style>