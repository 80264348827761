<script setup>
import { ref } from "vue";
import { hourAndMinutes, limitText } from "../../../libs/formaters.js"
import ContentKind from "./ContentKind.vue";

const props = defineProps({
  course: {
    type: Object
  }
})

</script>
<template>
  <article class="relative px-2 border py-2 border-secondary-30 rounded-2xl bg-white hover:bg-secondary-20 min-h-[97px] w-full">
    <div class="grid grid-cols-[77px_auto] gap-4 w-full">
        <figure class="flex justify-center items-center  overflow-hidden size-[77px] p-2 rounded" >
          <img loading="lazy" class="w-full h-full object-cover object-center"   :src="course.featured_image" aria-hidden="true" alt="Icono de la tecnología">
        </figure>
        <div class="flex flex-col justify-between min-h-[77px] w-full">
          <div class="grid-item-3">
            <h3 class="lg:text-[22px] text-[18px]  font-semibold leading-[26px] text-gray-900">
              <a class=" extend" :href="`/blog/${course.slug}/`">
                <slot>
                  {{limitText(course.name, 8)}}
                </slot>
              </a>
            </h3> 
          </div>
          <div class="flex justify-between w-full">
            <ContentKind :kind="'blog'" :kindLabel="'Blog'" />
          </div>
        </div>
    </div>
  </article>
</template>
<style>
.ellipsis-2-lines {
  display: block;
  width: 250px;
  height: 3em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.ellipsis-2-lines::after {
  content: '...';
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding-left: 0.5em;
}

</style>