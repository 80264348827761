<template>
    <ais-instant-search
      v-click-outside="hiddeInfo"
      id="search"
      class="lg:w-[50px] search-field relative"
     :initial-ui-state="[config.algolia_index]"
      :future="{
        preserveSharedStateOnUnmount: true
      }"
      :search-client="searchClient" 
      :index-name="config.algolia_index"
      :insights="configEvents"
    >
    <div class="w-full">
      <div class="input-wrapp relative">
        <ais-search-box autofocus>
          <template v-slot="{ currentRefinement, refine }">
              <input
              tabindex="0"
              autofocus
              id="search-input"
              @input="typeSearch(refine, $event.currentTarget.value)"
              @keyup.enter="search"
              :value="currentRefinement"
              placeholder="¿Qué quieres aprender hoy?"
              type="text"
              class="pl-[37px] text-white w-full h-12 px-4 rounded-md border-0 bg-secondary-90 focus:ring-0 focus:outline-none"
              />
            </template>
          </ais-search-box>
      </div>
      </div>
      <button tabindex="1" id="search-btn" @click="limitResults === 0 && openClose()" class="absolute left-[9px] size-[35px] flex justify-center items-center rounded cursor-pointer">
        <svg
        class="size-[20px]"
        width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M3.16668 7.33331C3.16668 4.75598 5.25601 2.66665 7.83334 2.66665C10.4107 2.66665 12.5 4.75598 12.5 7.33331C12.5 8.59067 12.0028 9.73188 11.1942 10.571C11.1714 10.5885 11.1495 10.6077 11.1286 10.6286C11.1078 10.6495 11.0886 10.6714 11.071 10.6941C10.2319 11.5027 9.0907 12 7.83334 12C5.25601 12 3.16668 9.91064 3.16668 7.33331ZM11.5785 12.0212C10.552 12.8423 9.25002 13.3333 7.83334 13.3333C4.51963 13.3333 1.83334 10.647 1.83334 7.33331C1.83334 4.0196 4.51963 1.33331 7.83334 1.33331C11.1471 1.33331 13.8333 4.0196 13.8333 7.33331C13.8333 8.74999 13.3424 10.052 12.5213 11.0784L14.9714 13.5286C15.2318 13.7889 15.2318 14.2111 14.9714 14.4714C14.7111 14.7318 14.289 14.7318 14.0286 14.4714L11.5785 12.0212Z" fill="white"/>
      </svg>
      </button>
      <button @click="hiddeInfo" class="js-reset absolute right-4 size-[35px] z-20 lg:flex justify-center items-center rounded cursor-pointer">
        <svg class={Astro.props.class} width="11" height="11" viewBox="0 0 7 7" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M.147 6.145a.5.5 0 1 0 .709.708L3.5 4.208l2.645 2.645a.5.5 0 0 0 .708-.708L4.208 3.5 6.853.855a.5.5 0 1 0-.709-.708L3.5 2.792.855.147a.5.5 0 0 0-.708.708L2.792 3.5.147 6.145Z" fill="currentColor"/></svg>
      </button>
      <div v-if="limitResults > 0"  class="fixed flex justify-center items-start pt-2 top-[137px] lg:top-16 left-0 w-screen h-screen bg-[#101828c2] z-50 top-100px" @click="hiddeInfo">
        <div class="lg:max-w-[1102px] xl:w-[1102px] rounded-[20px] bg-white  grid grid-cols-1 lg:gap-x-10 lg:grid-cols-2 py-4 px-4 lg:px-10 justify-center h-auto relative top-100px">
          <div>
            <div class="text-secondary-100 text-[22px] font-semibold mb-3">Formaciones</div>
            <ais-infinite-hits
              :transform-items="transformItem"
              :class-names="{
                'ais-InfiniteHits-list': 'grid grid-cols-1  bg-transparent h-[calc(100vh_-_200px)] overflow-y-auto lg:h-auto lg:overflow-y-visible',
                'ais-InfiniteHits-item': 'ais-Hits-item-custom  ',
                'ais-InfiniteHits-loadMore': 'hidden',
              }"
            >
              <template v-slot:item="{ item }">
                <div v-if="item" class="">
                  <component :is="item.model === 'Path' ? HorizontalListCard : HorizontalPrimaryCard" :course="item">
                    <ais-highlight  :class-names="{'ais-Highlight' : 'text-highLight', 'ais-Highlight-highlighted ': 'text-highLighted'}" attribute="name" :hit="item" />
                  </component>
                </div>
              </template>
            </ais-infinite-hits>
          <ais-state-results class="w-full">
            <template v-slot="{ results: { hits } }">
              <div class="flex w-full text-b2b-primary-50 justify-center container" v-show="!hits.length"> 
                ¡Vaya! No hemos encontrado resultados para tu búsqueda
              </div> 
              <div v-show="hits.length" class="w-full flex justify-end">
                <button @click="search" class="text-[14px] font-semibold mt-2 text-secondary-70">Ver más formaciones</button>
              </div>
            </template>
          </ais-state-results>
          </div>
          <div>
            <div class="text-secondary-100 text-[22px] font-semibold mb-3">Artículos</div>
            <ais-index :index-name="config.algolia_posts_index">
              <ais-infinite-hits
                :transform-items="transformItem"
                :class-names="{
                  'ais-InfiniteHits-list': 'grid grid-cols-1  bg-transparent h-[calc(100vh_-_200px)] overflow-y-auto lg:h-auto lg:overflow-y-visible',
                  'ais-InfiniteHits-item': 'ais-Hits-item-custom  ',
                  'ais-InfiniteHits-loadMore': 'hidden',
                }"
              >
                <template v-slot:item="{ item }">
                  <div v-if="item" class="">
                    <HorizontalPostCard :course="item">
                      <ais-highlight  :class-names="{'ais-Highlight' : 'text-highLight', 'ais-Highlight-highlighted ': 'text-highLighted'}" attribute="name" :hit="item" />
                    </HorizontalPostCard>
                  </div>
                </template>
              </ais-infinite-hits>
              <ais-state-results class="w-full">
                <template v-slot="{ results: { hits } }">
                  <div class="flex w-full text-b2b-primary-50 justify-center container" v-show="!hits.length"> 
                    ¡Vaya! No hemos encontrado resultados para tu búsqueda
                  </div> 
                  <div v-show="hits.length" class="w-full flex justify-end">
                    <button @click="searchBlog" class="text-[14px] font-semibold mt-2 text-secondary-70">Ver más artículos</button>
                  </div>
                </template>
              </ais-state-results>
            </ais-index>
          </div>
        </div>
      </div>
    <ais-configure :hitsPerPage="limitResults" />
  </ais-instant-search>
</template>

<script setup>
import { ref } from 'vue'
import { AisInstantSearch, AisSearchBox, AisHighlight, createWidgetMixin, AisStateResults } from 'vue-instantsearch/vue3/es';
import { connectInfiniteHits } from 'instantsearch.js/es/connectors'
import algoliasearch from 'algoliasearch/lite'
import { api } from '@/services/api'
import HorizontalPrimaryCard from './HorizontalPrimaryCard.vue'
import HorizontalListCard from './HorizontalListCard.vue'
import HorizontalPostCard from './HorizontalPostCard.vue'
import {limitText } from "@/libs/formaters.js"
const config =  await api.get(`${pathAPI}/api/public/astro/naut/`)
const algoliaClient = algoliasearch(config.algolia_app_id,config.algolia_api_key)
const searchClient = {
  ...algoliaClient,
  search (requests) {
    let timer = null
    const shouldSearch = requests.some(({ params: { query } }) => query !== '')
    if (!shouldSearch) {
      return Promise.resolve({
        results: requests.map(() => ({
          hits: [],
          nbHits: 0,
          nbPages: 0,
          page: 0,
          processingTimeMS: 0
        }))
      })
      return
    } 
    const newRequests = requests.map((request) => {
      if (!request.params.query || request.params.query.length === 0) {
        request.params.analytics = false
      } else {
        request.params.analytics = true
      }
      return request
    })
      let user_token = getCookie('mtc_id')
    window.aa('setUserToken', user_token)
    return algoliaClient.search(newRequests)
  }
}
createWidgetMixin('ais-infinite-hits', connectInfiniteHits)
const configEvents = ref({
  insightsClient: window.aa,
  onEvent: (event, aa) => {
    const { insightsMethod, payload } = event
    if (payload.eventName === 'Course clicked in Academy') {
      aa('convertedObjectIDsAfterSearch', {
        eventName: 'DynamicReordering',
        queryID: payload.queryID,
        objectIDs: payload.objectIDs,
        clickedObjectIDs: payload.objectIDs,
        index: payload.index,
        timestamp: payload.timestamp,
        userToken: payload.userToken
      })
      aa(insightsMethod, payload)
    }
    if (payload.eventName === 'Hits Viewed') {
      return false
    }
  }
})

const inputValue = ref('')
const inputState = ref('close')
const limitResults = ref(0)
const emit = defineEmits(['change'])
const openClose = () => {
  inputState.value = inputState.value === 'close' ? 'open' : 'close'
  if (inputState.value === 'open') {
    document.querySelector('#search-input').focus()
  }
  document.querySelector('[data-search]').dataset.search = inputState.value 

}
const transformItem = (items) => {
  return items.map(item => {
    return {
      ...item,
      name: limitText(item.name, 8),
      _highlightResult: {
        name: {
          value: limitText(item.name, 8)
        }
      }
    }
  })
}
let timerId = null
let outRefine = null
const typeSearch = (refine, value) => {
  inputValue.value = value
  if (window.innerWidth < 1024) return
  outRefine = refine
  clearTimeout(timerId)
  timerId = setTimeout(() => {
    refine(value)
    inputValue.value === '' ? limitResults.value = 0 : limitResults.value = 4
  }, 333)
  
}
const search = () => {
const url = '/cursos/?search=' + inputValue.value
window.location.href = url
}
const searchBlog = () => {
const url = '/blog/search?item=' + inputValue.value
window.location.href = url
}

const hiddeInfo = () => {
  if (window.innerWidth < 1024) return
  if (inputState.value === 'close') return
  document.querySelector('#search-input').focus()
  document.querySelector('#search-input').value = ''
  openClose()
  if (outRefine) typeSearch(outRefine, '')
}

const vClickOutside = { // directive in veu 3
  mounted (el) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        hiddeInfo()
      }
    }
    document.body.addEventListener('click', el.clickOutsideEvent)
  },
  unmounted (el) {
    document.body.removeEventListener('click', el.clickOutsideEvent)
  }
}

</script>

<style>
.js-reset{
  display: none!important;
}
[data-search="open"] .js-reset{
  display: flex!important;
}
</style>