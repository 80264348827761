<script setup>
import { ref } from "vue";
import { hourAndMinutes, limitText } from "../../../libs/formaters.js"
import ContentKind from "./ContentKind.vue";
import LevelIcon from "./icons/LevelIcon.vue";
import ClockIcon from "./icons/ClockIcon.vue";
import Authors from "./Authors.vue";
import StarIcon from "./icons/StarIcon.vue";
const props = defineProps({
  course: {
    type: Object
  }
})
const levels = ref({
  'low': 'Principiante',
  'low-middle': 'Intermedio',
  'middle': 'Intermedio',
  'middle-advanced': 'Avanzado',
  'advanced': 'Avanzado'
})
</script>
<template>
  <article class="relative px-2 border py-2 border-secondary-30 rounded-2xl bg-white hover:bg-secondary-20 min-h-[97px] w-full">
    <div class="grid grid-cols-[77px_auto] gap-4 w-full">
        <figure v-if="course.logo_color_image === '' || !course.logo_color_image" :style="`background-color: ${course.color}`" class="flex justify-center items-center  size-[77px] p-2 rounded" >
          <img loading="lazy" onerror="this.src='/images/iconologo-ow-white.svg'" class="w-[52px] h-auto"   :src="course.logo_image" aria-hidden="true" alt="Icono de la tecnología">
        </figure>
        <figure v-else class="flex justify-center items-center size-[77px] md:size-[52px]  p-2 rounded-sm" >
          <img loading="lazy" onerror="this.src='/images/iconologo-ow-white.svg'" class="w-[52px] h-auto" :src="course.logo_color_image" alt="Icono de la tecnología">
        </figure>
        <div class="flex flex-col justify-between min-h-[77px] w-full">
          <div class="grid-item-3">
            <h3 class="lg:text-[22px] text-[18px]  font-semibold leading-[26px] text-gray-900">
              <a class=" extend" :href="`/cursos/${course.slug}/`">
                <slot>
                  {{limitText(course.name, 8)}}
                </slot>
              </a>
            </h3> 
          </div>
          <div class="flex justify-between w-full">
            <ContentKind :kind="course.kind" :kindLabel="course.label" />
            <div class="flex gap-3">
              <div class="grid-item-5 flex items-center text-xs">
                <ClockIcon class="mr-2" />
                {{hourAndMinutes(course.video_duration_in_minutes *60)}}
              </div>
              <div v-if="course.avg_general_rating && course.avg_general_rating >= 4" class="flex items-center gap-[2px] text-xs">
                <StarIcon class="size-3 mb-[2px]" /> {{ course.avg_general_rating }}
              </div>
            </div>
          </div>
        </div>
    </div>
  </article>
</template>
<style>
.ellipsis-2-lines {
  display: block;
  width: 250px;
  height: 3em;
  line-height: 1.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
}

.ellipsis-2-lines::after {
  content: '...';
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: white;
  padding-left: 0.5em;
}

</style>